import httpStatus from 'http-status';

export const getErrorResponseMessage = (error) => {
    if (
        error.graphQLErrors &&
        error.graphQLErrors.length > 0 &&
        (error.graphQLErrors[0].status === httpStatus['400'] || error.graphQLErrors[0].status === httpStatus['409'])
    ) {
        return error.graphQLErrors[0].message;
    }

    return 'Please try again';
};
