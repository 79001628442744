import {gql} from '@apollo/client';

export const couponFragmentV4 = gql`
    fragment ICouponV4 on CouponV4 {
        couponId
        category
        imageUrl
        brand
        clipStartDate
        clipEndDate
        expirationDate
        clipped
        value
        valueText
        description
        terms
        upcs
        offerState
    }
`;

export const getCouponsV4ForCartItemUpcs = gql`
    ${couponFragmentV4}

    query GetCouponsV4ForCartItemUpcs($fuelSaverCardUuid: String, $filters: CouponsFilter) {
        couponsV4(fuelSaverCardUuid: $fuelSaverCardUuid, filters: $filters) {
            ...ICouponV4
        }
    }
`;

export const getCouponsForCouponSwimlane = gql`
    ${couponFragmentV4}

    query GetCouponsForCouponSwimlane($fuelSaverCardUuid: String, $filters: CouponsFilter) {
        couponsV4(fuelSaverCardUuid: $fuelSaverCardUuid, filters: $filters) {
            ...ICouponV4
        }
    }
`;

export const getCouponV4ByCouponIdQuery = gql`
    ${couponFragmentV4}

    query GetCouponV4ByCouponIdQuery($couponId: ID!) {
        couponV4(couponId: $couponId) {
            ...ICouponV4
        }
    }
`;
