import {createClippedCouponMutationV4} from '../coupon-mutations';
import {graphqlClient} from '../../graphql-client';
import {couponFragmentV4} from '../../queries/coupon-queries';

const updateCacheCreateClippedCoupon = (cache, {data}) => {
    try {
        const couponIdPolicy = (couponId) => `CouponV4:{"couponId":"${couponId}"}`;

        const couponData = cache.readFragment({
            fragment: couponFragmentV4,
            id: couponIdPolicy(data.clipCouponV4.couponId)
        });

        if (couponData && couponData.couponId) {
            cache.writeFragment({
                data: {
                    ...couponData,
                    clipped: true
                },
                fragment: couponFragmentV4,
                id: couponIdPolicy(couponData.couponId)
            });
        }
    } catch (error) {}
};

export const createClippedCouponV4 = async (fuelSaverCardUuid, couponId, setIsClipped) => {
    const response = await graphqlClient().mutate({
        mutation: createClippedCouponMutationV4,
        update: (cache, data) => updateCacheCreateClippedCoupon(cache, data),
        variables: {
            couponId,
            fuelSaverCardUuid
        }
    });

    if (response.data && setIsClipped && response.data.clipCouponV4) {
        setIsClipped(true);
    }
};
