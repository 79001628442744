import {createContext, Dispatch, SetStateAction} from 'react';

export interface CheckoutContextProps {
    clpeApiFailure?: boolean;
    setClpeApiFailure?: Dispatch<SetStateAction<CheckoutContextProps['clpeApiFailure']>>;
}

export type CheckoutContextValue = CheckoutContextProps | null;

const CheckoutContext = createContext<CheckoutContextValue>(null);

export default CheckoutContext;
