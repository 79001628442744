import {gql} from '@apollo/client';

import {cartItemFragment} from '../queries/cart-item-queries';

export const createCartItemMutation = gql`
    mutation createCartItemProductMutation(
        $customerId: Int!
        $createCartItemInput: createCartItemInput!
        $clpeEnabled: Boolean! = false
        $substitutionDataEnabled: Boolean! = false
        $wicEnabled: Boolean = false
        $locationIds: [ID!]
    ) {
        createCartItem(customerId: $customerId, cartItemInput: $createCartItemInput) {
            ...ICartItem
        }
    }
    ${cartItemFragment}
`;

export const updateCartItemMutation = gql`
    mutation updateCartItem($customerId: Int!, $updateCartItemInput: updateCartItemInput!) {
        updateCartItem(customerId: $customerId, cartItemInput: $updateCartItemInput) {
            cartItemId
            quantity
        }
    }
`;
