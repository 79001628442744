import {ApolloError, OperationVariables, QueryResult} from '@apollo/client';

export default class ApolloGraphQLQueryResultParser<
    TData = any,
    TVariables extends OperationVariables = OperationVariables
> {
    // PROPERTIES
    public readonly errorEntities: string[] = [];

    // CONSTRUCTOR
    public constructor(apolloQuery: QueryResult<TData, TVariables>) {
        if (apolloQuery?.error !== undefined) {
            this.errorEntities = this.reduceErrorsByPathName(apolloQuery?.error);
        }
    }

    // METHODS
    private reduceErrorsByPathName(apolloError: ApolloError) {
        return apolloError.graphQLErrors.reduce((acc: string[], curr) => {
            if (curr.path) {
                acc.push(`${curr.path[curr.path.length - 1]}`);
            }

            return acc;
        }, []);
    }

    public hasErrorsByPathName(path: string) {
        return this.errorEntities.includes(path);
    }
}
