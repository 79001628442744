import {ApolloClient} from '@apollo/client';

import {deleteCartItemVariables} from 'autogen/deleteCartItem';
import {ICartItem} from 'autogen/ICartItem';
import {graphqlClient} from 'client/graphql/graphql-client';
import {EMPTY_CUSTOMER_ID} from 'client/graphql/graphql-client-utils';

import {createCartItemMutation, updateCartItemMutation} from '../product-card-mutations';
import {
    updateCartItemsAfterCreate,
    updateCartItemsAfterDelete
} from '../mutation-helpers/product-card-mutation-helpers';
import {deleteCartItemMutation} from '../cart-mutations';

export const createCartItemAction = ({createCartItemInput}) =>
    graphqlClient().mutate({
        errorPolicy: 'all',
        mutation: createCartItemMutation,
        update: (cache, data) => updateCartItemsAfterCreate(cache, data.data.createCartItem),
        variables: {
            createCartItemInput
        }
    });

export const updateCartItemAction = (updateCartItemInput) => {
    return graphqlClient().mutate({
        errorPolicy: 'all',
        mutation: updateCartItemMutation,
        variables: {
            updateCartItemInput
        }
    });
};

export const deleteCartItemAction = async ({cartItemId, cartId}: {cartItemId: string; cartId: number}) => {
    const client = graphqlClient() as ApolloClient<any>;

    return client.mutate<ICartItem, deleteCartItemVariables>({
        errorPolicy: 'all',
        mutation: deleteCartItemMutation,
        update: (cache) => updateCartItemsAfterDelete(cache, cartItemId, cartId),
        variables: {
            cartItemId: Number(cartItemId),
            customerId: EMPTY_CUSTOMER_ID
        }
    });
};
