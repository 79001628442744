import type {DataProxy} from '@apollo/client';

import {IProductCardCartItems} from 'autogen/IProductCardCartItems';

import {productCardCartItemsFragment} from '../../queries/cart-item-queries';

export const updateCartItemsAfterCreate = (client, createCartItem) => {
    const cartId = createCartItem.cartId;
    const cartCacheRef = `cart:{"cartId":"${cartId}"}`;

    const {cartItems} = client.readFragment({
        fragment: productCardCartItemsFragment,
        fragmentName: 'IProductCardCartItems',
        id: cartCacheRef
    });

    client.writeFragment({
        data: {
            __typename: 'cart',
            cartId: `${cartId}`,
            cartItems: [...cartItems, createCartItem]
        },
        fragment: productCardCartItemsFragment,
        fragmentName: 'IProductCardCartItems',
        id: cartCacheRef
    });
};

export const updateCartItemsAfterDelete = (client: DataProxy, cartItemId: string, cartId: number) => {
    const cartCacheRef = `cart:{"cartId":"${cartId}"}`;

    const readResult = client.readFragment<IProductCardCartItems>({
        fragment: productCardCartItemsFragment,
        fragmentName: 'IProductCardCartItems',
        id: cartCacheRef
    });

    if (!readResult) {
        return;
    }

    const {cartItems} = readResult;

    client.writeFragment({
        data: {
            __typename: 'cart',
            cartId: `${cartId}`,
            cartItems: cartItems?.filter((item) => item.cartItemId !== cartItemId)
        },
        fragment: productCardCartItemsFragment,
        fragmentName: 'IProductCardCartItems',
        id: cartCacheRef
    });
};
