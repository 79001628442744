import {useQuery} from '@apollo/client';
import {ICartItem} from '@hy-vee/shared-utils-aisles-online/lib/substitution/substitution-quantity-types';
import {IDType} from '@hy-vee/ts-utils';
import Cookies from 'js-cookie';

import {
    getActiveCart_carts,
    getActiveCart_carts_cartItems,
    getActiveCart as IGetActiveCart
} from 'autogen/getActiveCart';
import {FulfillmentType} from 'autogen/globalTypes';
import {isFulfillmentTimeSelected} from 'client/utils/view-helpers/fulfillment-view-helpers';
import AlcoholWarningTypes from 'client/enums/cart-alcohol-warning-types';
import {
    GetBasicCustomerInformation,
    GetBasicCustomerInformationVariables,
    GetBasicCustomerInformation_carts
} from 'autogen/GetBasicCustomerInformation';
import {getActiveCart, getBasicCustomerInformation} from 'client/graphql/queries/cart-queries';
import useCheckoutContext from 'client/context/checkout';
import {useFeatureToggle} from 'client/context/feature-toggle';
import {EWIC_STORES} from 'client/enums/feature-toggle-names';
import {TWO_LEGGED_AUTH_TYPE} from 'client/enums/auth-types';
import {getMadeToOrderCart} from 'client/graphql/queries/mto-queries';
import ApolloGraphQLQueryResultParser from 'client/utils/apollo-graphql-query-result-parser';

import {getMyCustomerActiveCartId} from '../graphql/queries/grocery-options-queries';

import {useCustomer, useCustomerData} from './customer-hooks';
import {useLocationId} from './location-hooks';

export const useActiveCartId = (): number | null => {
    const {data} = useQuery(getMyCustomerActiveCartId, {
        fetchPolicy: 'cache-only'
    });

    const activeCartId = data?.myCustomer?.activeCart?.cartId;

    return activeCartId || null;
};

const getAlcoholWarningType = (cart: getActiveCart_carts): AlcoholWarningTypes | null => {
    if (cart.fulfillmentType === FulfillmentType.DELIVERY) {
        return AlcoholWarningTypes.DELIVERY_WARNING;
    }

    if (
        cart.fulfillmentLocation?.fulfillmentTimes?.find((fulfillmentTime) =>
            isFulfillmentTimeSelected(fulfillmentTime, cart)
        )?.preventAlcohol
    ) {
        return AlcoholWarningTypes.TIME_SLOT_WARNING;
    }

    return null;
};

export const getActiveCartItemsAndWarnings = (
    separateAlcoholTransactionFeatureToggleEnabled: boolean,
    cart: getActiveCart_carts
): {
    activeAlcoholItems: getActiveCart_carts_cartItems[];
    groceryItems: getActiveCart_carts_cartItems[];
    shouldRenderAlcoholSeparately: boolean;
    alcoholWarningType: AlcoholWarningTypes | null;
} => {
    if (!cart.cartItems) {
        return {
            activeAlcoholItems: [],
            alcoholWarningType: null,
            groceryItems: [],
            shouldRenderAlcoholSeparately: false
        };
    }

    if (!separateAlcoholTransactionFeatureToggleEnabled || !cart.fulfillmentLocation?.requireSeparateAlcoholOrder) {
        return {
            activeAlcoholItems: [],
            alcoholWarningType: null,
            groceryItems: cart.cartItems,
            shouldRenderAlcoholSeparately: false
        };
    }

    const activeAlcoholItems: getActiveCart_carts_cartItems[] = [];
    const groceryItemsWithoutAlcohol: getActiveCart_carts_cartItems[] = [];

    for (const cartItem of cart.cartItems) {
        if (cartItem.storeProduct?.isActive && cartItem.storeProduct.isAlcohol) {
            activeAlcoholItems.push(cartItem);
        } else if (!cartItem.storeProduct?.isAlcohol) {
            groceryItemsWithoutAlcohol.push(cartItem);
        }
    }

    const alcoholWarningType = getAlcoholWarningType(cart);

    return {
        activeAlcoholItems,
        alcoholWarningType,
        groceryItems: groceryItemsWithoutAlcohol,
        shouldRenderAlcoholSeparately: Boolean(activeAlcoholItems.length)
    };
};

interface IUseCartItems {
    activeCart: GetBasicCustomerInformation_carts | null;
    getCartItem: (productId: IDType) => ICartItem | null;
    getCartItemQuantity: (productId: IDType) => number;
}

export const useCartItems = (includeProductCardData?: boolean): IUseCartItems => {
    const {customerId, customerUuid} = useCustomer();

    const {data} = useQuery<GetBasicCustomerInformation, GetBasicCustomerInformationVariables>(
        getBasicCustomerInformation,
        {
            fetchPolicy: 'cache-only',
            skip: !customerId,
            variables: {
                customerId: Number(customerId),
                customerUuid: customerUuid?.toLowerCase(),
                includeProductCardData
            }
        }
    );

    const activeCart = data?.carts ? data.carts[0] : null;

    const getCartItem = (productId: IDType): ICartItem | null => {
        if (!activeCart?.cartItems) return null;

        const cartItem = activeCart?.cartItems?.find((item) => item && Number(item.productId) === Number(productId));

        return cartItem || null;
    };

    const getCartItemQuantity = (productId: IDType): number => {
        const cartItem = getCartItem(productId);

        return cartItem ? Number(cartItem.quantity) : 0;
    };

    return {
        activeCart,
        getCartItem,
        getCartItemQuantity
    };
};

export const useCartPageQueries = () => {
    const {setClpeApiFailure} = useCheckoutContext();
    const {featuresEnabled} = useFeatureToggle();

    const wicEnabled = featuresEnabled([EWIC_STORES]);

    const {storeId: cartStoreId} = useCustomerData();

    const storeId = cartStoreId;
    const {locationIds, locationIdLoading} = useLocationId(storeId?.toString());

    // Cart data
    const cartQuery = useQuery<IGetActiveCart>(getActiveCart, {
        errorPolicy: 'all',
        skip: locationIdLoading,
        variables: {
            clpeEnabled: true,
            locationIds,
            wicEnabled
        }
    });
    const {data: grocery, loading: groceryDataLoading, refetch} = cartQuery;
    const groceryData: IGetActiveCart & {refetch?: Function} = grocery
        ? {
              ...grocery,
              refetch
          }
        : ({} as IGetActiveCart);

    // Validate CLPE API is working
    /* istanbul ignore else*/
    const hasClpeApiErrors = new ApolloGraphQLQueryResultParser(cartQuery).hasErrorsByPathName('clpeCartPromotions');

    if (hasClpeApiErrors) {
        setClpeApiFailure && setClpeApiFailure(hasClpeApiErrors);
    }

    // Made To Order data
    const mtoCartUUID = Cookies.get('ecomCartUUID');

    const {data: mtoData, loading: mtoDataLoading} = useQuery(getMadeToOrderCart, {
        skip: !mtoCartUUID,
        variables: {
            authType: TWO_LEGGED_AUTH_TYPE,
            cartId: mtoCartUUID
        }
    });

    return {
        groceryData,
        loading: groceryDataLoading || mtoDataLoading || locationIdLoading,
        mtoData
    };
};
