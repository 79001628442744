import {createClippedCouponV4} from '../graphql/mutations/mutation-actions/coupon-actions';
import {getCouponByUpc} from '../utils/coupon-helpers';
import {getCouponV4ByCouponIdQuery} from '../graphql/queries/coupon-queries';
import {graphqlClient} from '../graphql/graphql-client';

export const createClippedCouponIfNotAlreadyClippedV4 = async (
    fuelSaverCardUuid,
    couponId,
    setIsClipped = () => {}
) => {
    const {data} = await graphqlClient().query({
        query: getCouponV4ByCouponIdQuery,
        variables: {
            couponId,
            fuelSaverCardUuid
        }
    });

    if (data.couponV4 && fuelSaverCardUuid && !data.couponV4.clipped) {
        await createClippedCouponV4(fuelSaverCardUuid, couponId, setIsClipped);
    }
};

export const createClippedCouponIfNotAlreadyClippedByUpc = async (fuelSaverCardUuid, upc) => {
    const coupon = await getCouponByUpc(`0${upc}`);

    if (coupon) {
        createClippedCouponIfNotAlreadyClippedV4(fuelSaverCardUuid, coupon.couponId);
    }
};
