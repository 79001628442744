import {useContext} from 'react';

import CheckoutContext from './context';

const useCheckoutContext = () => {
    const checkoutContext = useContext(CheckoutContext);

    if (checkoutContext === null) {
        throw new Error('useCheckoutContext hook must be instantiated inside the Checkout Context Provider.');
    }

    return checkoutContext;
};

export default useCheckoutContext;
