import {graphqlClient} from '../graphql/graphql-client';
import {getCouponsV4ForCartItemUpcs} from '../graphql/queries/coupon-queries';

export const convertToQuotientUpc = (givenUpc) => {
    const couponWithLeadingZero = `0${givenUpc}`;
    const sum = couponWithLeadingZero
        .trim()
        .slice(0, 13)
        .split('')
        .reduce((acc, digit, index) => {
            const numToAdd = index % 2 === 1 ? Number(digit) : digit * 3;

            return acc + numToAdd;
        }, 0);

    const remainder = sum % 10;

    const checkDigit = (10 - remainder) % 10;

    return `${couponWithLeadingZero.trim().slice(0, 13)}${checkDigit}`;
};

export const getCouponByUpc = async (upc) => {
    const quotientUpc = convertToQuotientUpc(upc);

    const result = await graphqlClient().query({
        query: getCouponsV4ForCartItemUpcs,
        variables: {
            filters: {
                upc: [quotientUpc]
            }
        }
    });

    if (result && result.data && result.data.couponsV4.length) {
        return result.data.couponsV4[0];
    }

    return null;
};
