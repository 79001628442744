import {gql} from '@apollo/client';

export const createClippedCouponMutationV4 = gql`
    mutation CreateClippedCouponMutationV4($couponId: ID!, $fuelSaverCardUuid: String!) {
        clipCouponV4(couponId: $couponId, fuelSaverCardUuid: $fuelSaverCardUuid) {
            clipped
            couponId
        }
    }
`;
